<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Users</div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
          </div>
        </div>
      </v-col>

      <form
        v-on:submit.prevent="searchUsers()"
        class="conFormSubmit conSearchForm mt-5"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="searchQuery"
              outlined
              hide-details="auto"
              @input="searchUsers"
              class="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </form>
    </v-row>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="7"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
              <template v-slot:[`item.avatar`]="{ item }">
                <div class="conOfImg">
                  <img :src="item.avatar" alt="" />
                </div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog scrollable v-model="dialogProject" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="eachLabel">Name</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Name"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.name"
                    required
                    :error-messages="nameErrors"
                    @input="$v.formItem.name.$touch()"
                    @blur="$v.formItem.name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Phone</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Phone"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.phone"
                    required
                    :error-messages="phoneErrors"
                    @input="$v.formItem.phone.$touch()"
                    @blur="$v.formItem.phone.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Email</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Email"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.email"
                    autocomplete="new-email"
                    required
                    :error-messages="emailErrors"
                    @input="$v.formItem.email.$touch()"
                    @blur="$v.formItem.email.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <div class="conOfPasswordReset">
                    <div class="conEnableEdit ml-3" v-if="passDisableEdit">
                      <v-btn
                        text
                        class="changePass"
                        :ripple="false"
                        @click="resetPassword"
                        >Reset Password
                      </v-btn>
                    </div>
                    <div class="passwordCon">
                      <label class="eachLabel">Password</label>
                      <v-text-field
                        class="eachInput"
                        placeholder="Password"
                        outlined
                        solo
                        hide-details="auto"
                        v-model="formItem.password"
                        autocomplete="new-password"
                        required
                        :error-messages="passwordErrors"
                        @input="$v.formItem.password.$touch()"
                        @blur="$v.formItem.password.$touch()"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :disabled="passDisableEdit"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Confirm Password</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Confirm Password"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.confirm_password"
                    autocomplete="new-confirm-password"
                    required
                    :error-messages="confirm_passwordErrors"
                    @input="$v.formItem.confirm_password.$touch()"
                    @blur="$v.formItem.confirm_password.$touch()"
                    :append-icon="
                      showconfirm_password ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="showconfirm_password ? 'text' : 'password'"
                    @click:append="showconfirm_password = !showconfirm_password"
                    :disabled="passDisableEdit"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <label class="eachLabel">Department</label>
                  <v-select
                    outlined
                    v-model="formItem.parent_id"
                    item-text="name_en"
                    item-value="id"
                    :items="listDataDepartment"
                    placeholder="Department"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    append-icon="mdi-chevron-down"
                    required
                    :error-messages="parent_idErrors"
                    @input="$v.formItem.parent_id.$touch()"
                    @blur="$v.formItem.parent_id.$touch()"
                    :disabled="isLoadingDepList"
                    :loading="isLoadingDepList"
                    @change="loadSubDepartements(formItem.parent_id)"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    listDataDepartmentSub && listDataDepartmentSub.length > 0
                  "
                >
                  <label class="eachLabel">Sub-Department</label>
                  <v-select
                    outlined
                    v-model="formItem.child_id"
                    item-text="name_en"
                    item-value="id"
                    :items="listDataDepartmentSub"
                    placeholder="Sub-Department"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    append-icon="mdi-chevron-down"
                    :disabled="disabledSubDepartment"
                    :loading="isLoadingDepSubList"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Role</label>
                  <v-select
                    outlined
                    v-model="formItem.role_id"
                    item-text="name_en"
                    item-value="id"
                    :items="listDataRoles"
                    placeholder="Role"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    append-icon="mdi-chevron-down"
                    required
                    :error-messages="role_idErrors"
                    @input="$v.formItem.role_id.$touch()"
                    @blur="$v.formItem.role_id.$touch()"
                    :disabled="isLoadingRolesList"
                    :loading="isLoadingRolesList"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Address</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Address"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.address"
                    required
                    :error-messages="addressErrors"
                    @input="$v.formItem.address.$touch()"
                    @blur="$v.formItem.address.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Bio</label>
                  <v-text-field
                    class="eachInput"
                    placeholder="Bio"
                    outlined
                    solo
                    hide-details="auto"
                    v-model="formItem.bio"
                    required
                    :error-messages="bioErrors"
                    @input="$v.formItem.bio.$touch()"
                    @blur="$v.formItem.bio.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label class="eachLabel">Country</label>
                  <v-select
                    outlined
                    v-model="formItem.country_id"
                    item-text="name_en"
                    item-value="id"
                    :items="listDatacountries"
                    placeholder="Country"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    append-icon="mdi-chevron-down"
                    required
                    :error-messages="country_idErrors"
                    @input="$v.formItem.country_id.$touch()"
                    @blur="$v.formItem.country_id.$touch()"
                    :disabled="isLoadingcountriesList"
                    :loading="isLoadingcountriesList"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import {
  required,
  email,
  sameAs,
  minLength,
  numeric,
} from "vuelidate/lib/validators";
import EmptyState from "@/modules/shared/components/emptystate";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        name: { required },
        phone: { required, minLength: minLength(5), numeric },
        email: { required, email },
        password: { required, minLength: minLength(8) },
        confirm_password: { required, sameAsPassword: sameAs("password") },
        parent_id: { required },
        role_id: { required },
        country_id: { required },
        address: { required },
        bio: { required },
      },
    };
    // if (this.listDataDepartmentSub && this.listDataDepartmentSub.length > 0) {
    //   validations.formItem.child_id = {
    //     required,
    //   };
    // }
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    searchQuery: "",
    dialogProject: false,
    isLoadingSave: false,
    isLoadingDepList: false,
    isLoadingDepSubList: false,
    isLoadingRolesList: false,
    isLoadingCountriesList: false,
    isLoading: false,
    disabledSubDepartment: true,
    passDisableEdit: false,
    editedIndex: -1,
    pagination: {
      current_page: 1,
    },
    formItem: {},
    defaultItem: {},
    headers: [
      {
        text: "image",
        align: "start",
        value: "avatar",
        sortable: false,
      },
      {
        text: "NAME",
        value: "name",
      },
      { text: "EMAIL", value: "email" },
      { text: "PHONE", value: "phone" },
      { text: "DEPARTMENT", value: "parent_name" },
      { text: "ROLE", value: "role_name" },
      { text: "ADDRESS", value: "address" },
      { text: "BIO", value: "bio" },
      { text: "COUNTRY", value: "country_name" },
      { text: "CREATE DATE", value: "created_at" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
    listData: [],
    listDataDepartment: [],
    listDataDepartmentSub: [],
    listDataRoles: [],
    listDatacountries: [],
    showPassword: false,
    showconfirm_password: false,
  }),
  components: { EmptyState },

  methods: {
    resetPassword() {
      this.passDisableEdit = false;
      this.formItem.password = "";
      this.formItem.confirm_password = "";
    },
    editItem(item) {
      if (item.children_id) this.loadSubDepartements(item.parent_id);
      this.passDisableEdit = true;
      this.editedIndex = item.id;
      let editFormItem = {
        name: item.name,
        phone: item.phone,
        email: item.email,
        address: item.address,
        bio: item.bio,
        parent_id: item.parent_id,
        child_id: item.children_id,
        role_id: item.role_id,
        country_id: item.country_id,
      };
      editFormItem.password = "12345678";
      editFormItem.confirm_password = "12345678";
      this.formItem = editFormItem;
      this.dialogProject = true;
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      if (this.passDisableEdit) this.formItem.password = null;
      delete this.formItem.confirm_password;

      if (this.formItem["role_id"])
        this.formItem["role_id"] = this.formItem.role_id.toString();
      if (this.formItem["country_id"])
        this.formItem["country_id"] = this.formItem.country_id.toString();
      if (this.formItem["parent_id"])
        this.formItem["parent_id"] = this.formItem.parent_id.toString();
      if (this.formItem["child_id"])
        this.formItem["child_id"] = this.formItem.child_id.toString();

      apiServices
        .update(this.editedIndex, "users", this.formItem)
        .then((res) => {
          if (res) {
            this.successMessage = res.message || "Successful";
            this.successSnackbar = true;

            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            errors.forEach((err) => {
              this.errorMessage = err;
            });
            this.errorSnackbar = true;
          }
        });
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      delete this.formItem.confirm_password;

      if (this.formItem["role_id"])
        this.formItem["role_id"] = this.formItem.role_id.toString();
      if (this.formItem["country_id"])
        this.formItem["country_id"] = this.formItem.country_id.toString();
      if (this.formItem["parent_id"])
        this.formItem["parent_id"] = this.formItem.parent_id.toString();
      if (this.formItem["child_id"])
        this.formItem["child_id"] = this.formItem.child_id.toString();

      apiServices.post("users", this.formItem).then((res) => {
        if (res) {
          this.successMessage = res.message || "Successful";
          this.successSnackbar = true;

          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
          let errors = Object.values(this.apiMessage)[0];
          errors.forEach((err) => {
            this.errorMessage = err;
          });
          this.errorSnackbar = true;
        }
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeProjectModal() {
      this.passDisableEdit = false;
      this.editedIndex = -1;
      this.dialogProject = false;
      this.listDataDepartmentSub = [];
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      const query = this.searchQuery
        ? `&search=${encodeURIComponent(this.searchQuery)}`
        : "";

      apiServices.get(`users?per_page=7&page=${page}${query}`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    searchUsers() {
      this.pagination.current_page = 1;
      this.getListData(this.pagination.current_page);
    },
    onSearchTxt() {
      this.pagination.current_page = 1;
      this.pathByOaramSearchTxt();
    },
    pathByOaramSearchTxt() {
      const query = Object.entries(this.searchTxt).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilterTxt) != JSON.stringify(query)) {
        this.$router.replace({
          name: "settingUsers",
          query: query,
        });
        this.updatedFilterTxt = query;
      }
    },
    getListDataDepartments() {
      this.isLoadingDepList = true;
      this.listDataDepartment = [];
      apiServices.get("departments?per_page=100").then((res) => {
        if (res) {
          this.isLoadingDepList = false;
          this.listDataDepartment = res.data;
        } else this.isLoadingDepList = false;
      });
    },
    loadSubDepartements(id) {
      this.isLoadingDepSubList = true;
      this.disabledSubDepartment = true;
      this.listDataDepartmentSub = [];
      apiServices.get(`departments/${id}`).then((res) => {
        if (res) {
          this.disabledSubDepartment = false;
          this.isLoadingDepSubList = false;
          if (res.data && res.data.length > 0)
            this.listDataDepartmentSub = res.data;
          else this.formItem.child_id = "";
        } else this.isLoadingDepSubList = false;
      });
    },
    getListDataRoles() {
      this.isLoadingRolesList = true;
      this.listDataRoles = [];
      apiServices.get("roles?per_page=100").then((res) => {
        if (res) {
          this.isLoadingRolesList = false;
          this.listDataRoles = res.data;
        } else this.isLoadingRolesList = false;
      });
    },
    getListDatacountries() {
      this.isLoadingcountriesList = true;
      this.listDatacountries = [];
      apiServices.get("countries").then((res) => {
        if (res) {
          this.isLoadingcountriesList = false;
          this.listDatacountries = res.data;
        } else this.isLoadingcountriesList = false;
      });
    },
  },
  computed: {
    ...mapGetters(["apiMessage"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New User" : "Edit User";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formItem.name.$dirty) return errors;
      !this.$v.formItem.name.required && errors.push("Name Is Required.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.formItem.phone.$dirty) return errors;
      !this.$v.formItem.phone.required && errors.push("Phone Is Required.");
      !this.$v.formItem.phone.numeric &&
        errors.push("Phone should be a number");
      !this.$v.formItem.phone.minLength &&
        errors.push("Phone must be at least 5 characters");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.formItem.email.$dirty) return errors;
      !this.$v.formItem.email.email && errors.push("Must be valid e-mail");
      !this.$v.formItem.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.formItem.password.$dirty) return errors;
      !this.$v.formItem.password.required &&
        errors.push("Password is required");
      !this.$v.formItem.password.minLength &&
        errors.push("must be at least 8 characters");
      return errors;
    },
    confirm_passwordErrors() {
      const errors = [];
      if (!this.$v.formItem.confirm_password.$dirty) return errors;
      !this.$v.formItem.confirm_password.required &&
        errors.push("Confirm Password is required");
      !this.$v.formItem.confirm_password.sameAsPassword &&
        errors.push("Passwords should match");
      return errors;
    },
    parent_idErrors() {
      const errors = [];
      if (!this.$v.formItem.parent_id.$dirty) return errors;
      !this.$v.formItem.parent_id.required &&
        errors.push("Department Is Required.");
      return errors;
    },
    // child_idErrors() {
    //   const errors = [];
    //   if (!this.$v.formItem.child_id.$dirty) return errors;
    //   !this.$v.formItem.child_id.required &&
    //     errors.push("Sub-Department Is Required.");
    //   return errors;
    // },
    role_idErrors() {
      const errors = [];
      if (!this.$v.formItem.role_id.$dirty) return errors;
      !this.$v.formItem.role_id.required && errors.push("Role Is Required.");
      return errors;
    },
    country_idErrors() {
      const errors = [];
      if (!this.$v.formItem.country_id.$dirty) return errors;
      !this.$v.formItem.country_id.required &&
        errors.push("country Is Required.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.formItem.address.$dirty) return errors;
      !this.$v.formItem.address.required && errors.push("Address Is Required.");
      return errors;
    },
    bioErrors() {
      const errors = [];
      if (!this.$v.formItem.bio.$dirty) return errors;
      !this.$v.formItem.bio.required && errors.push("Bio Is Required.");
      return errors;
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
    this.getListDataDepartments();
    this.getListDataRoles();
    this.getListDatacountries();
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_users.scss";
</style>
